export const config = {
    API_URL: 'https://matador.wame.sk/api/v1',
    FD_HEADERS: {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
            'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiY2NhNzQzZDFjODgxYzc4NGYyZjhlYmY4YzE1YWM2NjRkNGI0NmVmNTk1MTA4ZTQxMzBmZjA2YjZlZjQ1MDUyYWI4ZTI4N2JhODllZjM0ZjYiLCJpYXQiOjE2NzU0MzQzNjYuODE1NjYxLCJuYmYiOjE2NzU0MzQzNjYuODE1NjY3LCJleHAiOjE3MDY5NzAzNjYuNzU3MjU5LCJzdWIiOiIwMWdyOHB3ejVhZDN5NmgzbXR3YTg2eTg2dCIsInNjb3BlcyI6W119.q8NFQM-XzD91jiwLuqInjj6-tyuCMzwmDty5PiH4_ySPsln207H38S5h8UZWR05c6xZGJLZJj9Qwo5gkGn0s_KozOCexKvHKYeVNRfoSgqMEYl5yftJVNzk8RdUGWEa1uGEPTVgl_fK5aQP_PMpPttx2UBM72NeiJkjF1Cr5FzShnHaqU06hQrBn7ZBAw3VQl-DV6dKb9MlPPIBW5iCQb42LEZ9KCK3yPTcOU-Wm6NGRDLvIddFBezvrdTte5dsT7InsD1-pyE5qTKyhZbR_AQY6Qe0Z9smNvMXcZds98g3cAMyuEJDGb5t5iERznK1a5S_T_EOAWGEfTUyz__IX_ULvQ6Lw6FOaisDk-ZvKQpGLtARTJ1FrwgRSSzIPMB8HOw8-yBPAX1l64D8GOPjqZiu9Y8cNGsYNLRfHPlrElrGNjng4HVcEG8WxOZ6b-HIX18hhw5wsZXEdBR_I0zi2AJHT9BvnUVGGRY9wr52FVv3WSACdGWQLqb3Nt5A1rZkkBBLLVIXE0Y47ytsMXUsVWiswtSku6c4_URYEPGOXKVZAiPuPBp6YUHRepqyr-ehtsjsBRaz7tIHyHKTQU0p0BUFSF6A6jI8ZQ5_5fz66c_wlJhLfO8mZMfmYXuRfSpJwyCBPFsPQ1CibtUK2kXhE5pcZ-QlzfJXuzf8TS1ElEWk'
        }
    },
    JS_HEADERS: {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            // 'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiY2NhNzQzZDFjODgxYzc4NGYyZjhlYmY4YzE1YWM2NjRkNGI0NmVmNTk1MTA4ZTQxMzBmZjA2YjZlZjQ1MDUyYWI4ZTI4N2JhODllZjM0ZjYiLCJpYXQiOjE2NzU0MzQzNjYuODE1NjYxLCJuYmYiOjE2NzU0MzQzNjYuODE1NjY3LCJleHAiOjE3MDY5NzAzNjYuNzU3MjU5LCJzdWIiOiIwMWdyOHB3ejVhZDN5NmgzbXR3YTg2eTg2dCIsInNjb3BlcyI6W119.q8NFQM-XzD91jiwLuqInjj6-tyuCMzwmDty5PiH4_ySPsln207H38S5h8UZWR05c6xZGJLZJj9Qwo5gkGn0s_KozOCexKvHKYeVNRfoSgqMEYl5yftJVNzk8RdUGWEa1uGEPTVgl_fK5aQP_PMpPttx2UBM72NeiJkjF1Cr5FzShnHaqU06hQrBn7ZBAw3VQl-DV6dKb9MlPPIBW5iCQb42LEZ9KCK3yPTcOU-Wm6NGRDLvIddFBezvrdTte5dsT7InsD1-pyE5qTKyhZbR_AQY6Qe0Z9smNvMXcZds98g3cAMyuEJDGb5t5iERznK1a5S_T_EOAWGEfTUyz__IX_ULvQ6Lw6FOaisDk-ZvKQpGLtARTJ1FrwgRSSzIPMB8HOw8-yBPAX1l64D8GOPjqZiu9Y8cNGsYNLRfHPlrElrGNjng4HVcEG8WxOZ6b-HIX18hhw5wsZXEdBR_I0zi2AJHT9BvnUVGGRY9wr52FVv3WSACdGWQLqb3Nt5A1rZkkBBLLVIXE0Y47ytsMXUsVWiswtSku6c4_URYEPGOXKVZAiPuPBp6YUHRepqyr-ehtsjsBRaz7tIHyHKTQU0p0BUFSF6A6jI8ZQ5_5fz66c_wlJhLfO8mZMfmYXuRfSpJwyCBPFsPQ1CibtUK2kXhE5pcZ-QlzfJXuzf8TS1ElEWk'
        }
    }
}