import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios"
import {config} from "./config"

/* Import styles */
import './theme/fonts.scss'
import './theme/style.scss'
import 'bootstrap'

/* Translations */
import {createI18n} from 'vue-i18n';
import sk from './locale/sk.json';

const i18n = new createI18n({
    locale: 'sk',
    messages: {
        'sk': sk
    }
})

// Authorize requests
axios.interceptors.request.use((request) => {
    // set Bearer token if user is logged in
    if (store.getters.isLoggedIn) {
        request.headers['Authorization'] = `Bearer ${store.getters.token}`;
    }
    return request
})

// Create Vue App
const app = createApp(App)
// Set global variables
app.config.globalProperties.$axios = axios
app.config.globalProperties.$config = config
// Use plugins
app.use(store)
app.use(router)
app.use(i18n)
// Mount Vue App
app.mount('#zmen-form')