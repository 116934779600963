// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-3-2!../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-3-3!../../node_modules/sass-loader/dist/cjs.js??ref--9-oneOf-3-4!../../node_modules/bootstrap-icons/font/bootstrap-icons.css");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./fonts/LibreFranklin-VariableFont_wght.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@font-face{font-family:Libre Franklin;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:1 999}", ""]);
// Exports
module.exports = exports;
