import { createRouter, createWebHashHistory } from 'vue-router'
import store from "../store";

const routes = [
  {
    path: '',
    redirect: '/idea-form'
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      public: true
    },
    component: () => import( '../views/PageLogin.vue')
  },
  {
    path: '/register',
    name: 'Register',
    meta: {
      public: true
    },
    component: () => import( '../views/PageRegister.vue')
  },
  {
    path: '/idea-form',
    name: 'IdeaForm',
    meta: {
      public: false
    },
    component: () => import( '../views/PageIdeaForm.vue')
  },
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // console.log(from, to)

  if (store.getters.isLoggedIn) {
    if (to.meta.public) {
      next({name: 'IdeaForm'});
    } else {
      next();
    }
  } else {
    if (to.meta.public) {
      next();
    } else {
      next({name: 'Login'});
    }
  }
})

export default router
