import { createStore } from 'vuex'
import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: 'zmen-form'
})

export default createStore({
  state: {
    token: '',
    user: {}
  },
  getters: {
    isLoggedIn(state) {
      return state.token?.length > 0
    },
    token(state) {
      return state.token
    },
    user(state) {
      return state.user
    }
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload
    },
    setUser(state, payload) {
      state.user = payload
    }
  },
  actions: {
    token({commit}, payload) {
      commit('setToken', payload)
    },
    user({commit}, payload) {
      commit('setUser', payload)
    }
  },
  plugins: [vuexLocal.plugin]
})
