<template>
  <router-view/>
</template>

<script>
export default {
	methods: {
		logout() {
			this.$axios.post(`${this.$config.API_URL}/logout`, {}, this.$config.JS_HEADERS)
				.then((res) => {
					console.warn(res)
					
					this.$store.dispatch('token', '')
					this.$store.dispatch('user', {})
					
					this.$router.push({name: 'Login'})
				})
		},
	}
}
</script>

<style lang="scss">

</style>
